import React from 'react'
import Plaatjie from '@ubo/plaatjie'

import styled from 'styled-components'

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const StyledImageBlock = styled(Plaatjie)`
  height: 100%;
`

interface BackgroundImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BackgroundImage
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  fields,
}) => (
  <>
  <section className={`${fields?.fullWidth ? 'container-fluid' : 'container' } px-0 `} >
    <div className='row'>
      <div className="col-12 p-lg-0">
        <ImageContainer>
          <StyledImageBlock image={fields?.image} alt="" />
        </ImageContainer>
      </div>
    </div>
  </section>
  </>
  )

export default BackgroundImage
